import { AxiosResponse } from "axios";
import { instance, headers } from "./index";
import { UserRole } from "../types/userRole";

const userRoleApi = {
  getUserRoles: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<UserRole> }> = await instance.get(
      "/userRoles",
      headers()
    );
    return data;
  },
};

export default userRoleApi;
