import { createSlice } from "@reduxjs/toolkit";
import {
  getUsers,
  createUser,
  updateUser,
  getUserById,
} from "../actions/users";

export type UserDataType = {
  id: number;
  company_id: number;
  user_role_id: number;
  role: {
    id: number;
    code: string;
    name: string;
  };
  name: string;
  email: string;
  active: boolean;
  hotels: any;
};

export type UserType = {
  data: UserDataType;
  isLoading: boolean;
  errors: any;
};

export type ItemsType = {
  items: Array<UserType>;
  totalItems: number;
  itemsPerPage: number;
  isLoading: boolean;
};

const initialState: {
  newUser: any;
  user: any;
  users: ItemsType;
  isLoading: boolean;
  errors: any;
} = {
  newUser: {
    data: {} as UserDataType,
    errors: {},
    isLoading: false,
  } as UserType,
  user: {
    data: {} as UserDataType,
    errors: {},
    isLoading: false,
  } as UserType,
  users: {
    items: [] as Array<UserType>,
    totalItems: 0,
    itemsPerPage: 10,
    isLoading: false,
  },
  isLoading: false,
  errors: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setStateUsers(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    clearUser: (state) => {
      state.user.data = initialState.user.data;
      state.user.errors = initialState.user.errors;
      state.newUser.data = initialState.newUser.data;
      state.newUser.errors = initialState.newUser.errors;
    },
  },
  extraReducers: {
    [getUsers.pending.toString()]: (state, { payload }) => {
      state.users.isLoading = true;
    },
    [getUsers.fulfilled.toString()]: (state, { payload }) => {
      state.users = { ...payload };
      state.users.isLoading = false;
    },
    [getUserById.fulfilled.toString()]: (state, { payload }) => {
      state.user.data = { ...payload };
    },
    [createUser.fulfilled.toString()]: (state, { payload }) => {
      state.newUser.data = { ...payload };
      state.newUser.errors = null;
    },
    [createUser.rejected.toString()]: (state, { payload }) => {
      state.newUser.errors = payload;
    },
    [updateUser.fulfilled.toString()]: (state, { payload }) => {
      state.user.data = { ...state.user.data, ...payload };
      state.user.errors = null;
    },
    [updateUser.rejected.toString()]: (state, { payload }) => {
      state.user.errors = payload;
    },
  },
});

export const { setStateUsers, clearUser } = usersSlice.actions;

export default usersSlice.reducer;
