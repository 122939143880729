import { instance } from "./index";
import {
  SignInType,
  ResetPasswordType,
  ConfirmPasswordType,
  ForgotPasswordType,
} from "../types/auth";

const authApi = {
  signIn: async (values: SignInType | {}) => {
    const { data } = await instance.post("/auth/login", values);
    return data;
  },

  forgotPassword: async (values: ForgotPasswordType | {}) => {
    const { data } = await instance.post("/auth/forgot", values);
    return data;
  },

  resetPassword: async (values: ResetPasswordType | {}) => {
    const { data } = await instance.post("/auth/reset", values);
    return data;
  },

  createPassword: async (values: ConfirmPasswordType | {}) => {
    const { data } = await instance.post("/auth/invite", values);
    return data;
  },
};

export default authApi;
