import { createSlice } from "@reduxjs/toolkit";
import {NotificationItemsType, NotificationType} from "../../types/notificationsCenter";
import {getNotifications} from "../actions/notificationsCenter";

const initialState: {
  notifications: NotificationItemsType;
  isLoading: boolean;
  errors: any;
} = {
  notifications: {
    items: [] as Array<NotificationType>,
    totalItems: 0,
    itemsPerPage: 15,
    isLoading: false,
  },
  isLoading: false,
  errors: null,
};

const hotelsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setStateNotifications(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    resetNotifications(state) {
      return {
        ...state,
        notifications: initialState.notifications,
      };
    },
  },
  extraReducers: {
    [getNotifications.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getNotifications.fulfilled.toString()]: (state, { payload }) => {
      state.notifications = payload;
      state.isLoading = false;
    },
    [getNotifications.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export const { setStateNotifications, resetNotifications } = hotelsSlice.actions;

export default hotelsSlice.reducer;
