import { createSlice } from "@reduxjs/toolkit";
import {
  getForecasts,
  getForecastById,
  createForecast,
  updateForecast,
  updateForecastValues,
  deleteForecast,
  getForecastStatus,
  updateForecastStatus,
  uploadDuetto,
  uploadTimeplan,
  saveValueComment,
  getValueComments,
  getForecastComments,
  updateLastForecast,
} from "../actions/forecasts";
import { getProfile } from "../actions/user";

import { ForecastType, ForecastStatusType } from "../../types/forecasts";
import {
  setInitialValuesForTable,
  onFilterParameters,
} from "../../helpers/formsFields";
import { FIRST_COLUMN_PROGNOSIS } from "../../helpers/constants";

const initialState: {
  forecast: ForecastType | null;
  forecasts: Array<ForecastType> | [];
  forecastStatuses: Array<ForecastStatusType> | [];
  currentForecastId: number;
  isUpdatedForecast: boolean;
  isUpdatedStatus: boolean;
  forceUpdate: boolean;
  updateError: any;
  allParametersForecast: Array<any>;
  dataTable: Array<any>;
  initialValuesForTable: any;
  fieldsTable: Array<any>;
  valueComments: any;
  forecastComments: any;
  profileId: number | null;

  isLoading: boolean;
  errors: any;
  warning: any;
  reseted: boolean;
} = {
  forecast: null,
  forecasts: [],
  forecastStatuses: [],
  currentForecastId: 0,
  isUpdatedForecast: false,
  isUpdatedStatus: false,
  forceUpdate: false,
  dataTable: [],
  allParametersForecast: [],
  initialValuesForTable: {},
  fieldsTable: [],
  valueComments: {
    isLoading: false,
    myLatestComment: null,
    commentsHistory: [],
  },
  forecastComments: {
    allPublishedComments: [],
    latestComments: [],
    currentComments: [],
  },
  profileId: null,

  isLoading: false,
  errors: null,
  warning: null,
  updateError: null,
  reseted: false,
};

const forecastsSlice = createSlice({
  name: "forecasts",
  initialState,
  reducers: {
    setStateValues(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    resetForecast(state) {
      return { ...initialState, profileId: state.profileId };
    },
    resetChanges(state) {
      return {
        ...state,
        reseted: !state.reseted,
      };
    },
    setFilterParameters(state, { payload }) {
      return {
        ...state,
        dataTable: onFilterParameters({
          data: state?.allParametersForecast,
          valueFilter: payload,
        }),
      };
    },
    resetComments(state) {
      state.valueComments = initialState.valueComments;
    },
    setForceUpdate(state, { payload }) {
      state.forceUpdate = payload;
    },
  },
  extraReducers: {
    [getForecasts.pending.toString()]: (state) => {
      state.isUpdatedStatus = false;
      state.isLoading = true;
      state.errors = null;
    },
    [getForecasts.fulfilled.toString()]: (state, { payload }) => {
      state.forecasts = payload;
      state.isLoading = false;
    },
    [getForecasts.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload?.errors;
    },
    [getForecastById.pending.toString()]: (state) => {
      state.isUpdatedForecast = false;
      state.isLoading = true;
      state.errors = null;
    },
    [getForecastById.fulfilled.toString()]: (state, { payload }) => {
      state.forecast = payload;
      state.isLoading = false;
      state.dataTable = payload?.parameters;
      state.allParametersForecast = payload?.parameters;
      state.initialValuesForTable = setInitialValuesForTable(
        payload?.parameters,
        state?.profileId,
        state?.forecast?.id
      );
      state.fieldsTable = [FIRST_COLUMN_PROGNOSIS, ...payload.fields];
    },

    [getForecastById.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload?.errors;
    },
    [createForecast.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [createForecast.fulfilled.toString()]: (state, { payload }) => {
      state.forecast = payload;
      state.isLoading = false;
    },
    [createForecast.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload?.errors;
    },
    [updateForecast.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [updateForecast.fulfilled.toString()]: (state, { payload }) => {
      state.forecast = payload;
      state.isLoading = false;
    },
    [updateForecast.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload?.messages;
    },
    [updateForecastValues.pending.toString()]: (state) => {
      state.isLoading = true;
      state.updateError = null;
    },
    [updateForecastValues.fulfilled.toString()]: (state, { payload }) => {
      state.isUpdatedForecast = true;
      state.isLoading = false;
    },
    [updateForecastValues.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.updateError = payload?.message;
    },
    [deleteForecast.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [deleteForecast.fulfilled.toString()]: (state) => {
      state.forecasts = state.forecasts.filter(
        (item) => item.id !== state.currentForecastId
      );
      state.isLoading = false;
    },
    [deleteForecast.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload?.errors;
    },

    [getForecastStatus.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getForecastStatus.fulfilled.toString()]: (state, { payload }) => {
      state.forecastStatuses = payload;
      state.isLoading = false;
    },
    [getForecastStatus.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload?.errors;
    },
    [updateForecastStatus.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [updateForecastStatus.fulfilled.toString()]: (state, { payload }) => {
      state.isUpdatedForecast = true;
      state.isUpdatedStatus = true;
      state.isLoading = false;
      state.warning = payload?.warning;
    },
    [updateForecastStatus.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload?.message;
    },
    [updateLastForecast.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [updateLastForecast.fulfilled.toString()]: (state, { payload }) => {
      state.isUpdatedForecast = true;
      state.isUpdatedStatus = true;
      state.isLoading = false;
      state.warning = payload?.warning;
    },
    [updateLastForecast.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload?.message;
    },
    [uploadDuetto.pending.toString()]: (state) => {
      state.isLoading = true;
      state.updateError = null;
    },
    [uploadDuetto.fulfilled.toString()]: (state, { payload }) => {
      state.isUpdatedForecast = true;
      // state.isLoading = false;  it was did for excluding dubble spiner, couse next request getForecastById
    },
    [uploadDuetto.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.updateError = payload?.message;
    },
    [uploadTimeplan.pending.toString()]: (state) => {
      state.isLoading = true;
      state.updateError = null;
    },
    [uploadTimeplan.fulfilled.toString()]: (state, { payload }) => {
      state.isUpdatedForecast = true;
      // state.isLoading = false;  it was did for excluding dubble spiner, couse next request getForecastById
    },
    [uploadTimeplan.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.updateError = payload?.message;
    },
    [getValueComments.pending.toString()]: (state, { payload }) => {
      state.valueComments.isLoading = true;
    },
    [getValueComments.fulfilled.toString()]: (state, { payload }) => {
      state.valueComments = payload?.data;
      state.valueComments.isLoading = false;
    },
    [getForecastComments.fulfilled.toString()]: (state, { payload }) => {
      state.forecastComments.latestComments = payload?.data?.latestComments;
      state.forecastComments.allPublishedComments =
        payload?.data?.allPublishedComments;
      state.forecastComments.currentComments = payload?.data?.currentComments;
    },
    [getProfile.fulfilled.toString()]: (state, { payload }) => {
      state.profileId = payload?.id;
    },
  },
});

export const {
  setStateValues,
  resetForecast,
  resetComments,
  setFilterParameters,
  resetChanges,
  setForceUpdate,
} = forecastsSlice.actions;

export default forecastsSlice.reducer;
