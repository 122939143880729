import React, { ChangeEvent } from "react";
import { CFormGroup, CInput, CLabel, CInvalidFeedback } from "@coreui/react";
import { FormikErrors } from "formik";

type PropsType = {
  label?: string;
  name: string;
  value?: string | number | readonly string[] | undefined;
  type?: string;
  placholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  helperText?:
    | string
    | false
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  handleChange?: (e: string | ChangeEvent<any>) => void;
};

const Input: React.FC<PropsType> = ({
  label,
  name,
  value,
  type = "text",
  placholder = "",
  disabled = false,
  readOnly = false,
  error = false,
  helperText,
  handleChange,
}) => (
  <CFormGroup>
    {label && <CLabel htmlFor={name}>{label}</CLabel>}

    <CInput
      id={name}
      name={name}
      value={value}
      type={type}
      placeholder={placholder}
      disabled={disabled}
      onChange={handleChange}
      invalid={error}
      readOnly={readOnly}
    />

    {error && (
      <CInvalidFeedback className="text-danger">{helperText}</CInvalidFeedback>
    )}
  </CFormGroup>
);

export default Input;
