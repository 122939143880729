import {
  FIRST_COLUMN_PROGNOSIS,
  ROLES,
  STATUSES_USER,
  STATUSES_PROGNOSIS,
} from "./constants";
import { ChangedValuesType } from "../types/forecasts";

export const roleOptions = [
  { label: ROLES.ADMIN, value: 1 },
  { label: ROLES.COMPANY, value: 2 },
  { label: ROLES.APPROVER, value: 3 },
  { label: ROLES.INPUT_MAKER, value: 4 },
];

export const statusUserOptions = [
  { label: STATUSES_USER.ACTIVE, value: STATUSES_USER.ACTIVE },
  { label: STATUSES_USER.PENDING, value: STATUSES_USER.PENDING },
  { label: STATUSES_USER.BLOCKED, value: STATUSES_USER.BLOCKED },
];

export const statusPrognosisOptions = [
  { label: STATUSES_PROGNOSIS.CREATED, value: STATUSES_PROGNOSIS.CREATED },
  {
    label: STATUSES_PROGNOSIS.PENDING,
    value: STATUSES_PROGNOSIS.PENDING,
  },
  { label: STATUSES_PROGNOSIS.APPROVED, value: STATUSES_PROGNOSIS.APPROVED },
  { label: STATUSES_PROGNOSIS.ARCHIVED, value: STATUSES_PROGNOSIS.ARCHIVED },
];

export const setFieldsTable = (data: any) =>
  [
    {
      key: FIRST_COLUMN_PROGNOSIS,
    },
    ...(data?.length &&
      data[0].values?.map((item: any) => ({
        key: item.date,
        sorter: false,
        filter: false,
      }))),
  ] || [];

export const setDataTable = (data: any) => {
  let mainArray: Array<any> = [];

  data?.forEach((item: any) => {
    let object: any = {};
    let comments: Array<any> = [];
    const forecastValues = localStorage.getItem("forecastValues");
    const initialValues = forecastValues && JSON.parse(forecastValues);

    if (item.source) {
      object._classes = `${item.source}_color`;
    }

    if (item.group) {
      object._classes = "bold-row-table";
    }

    object[FIRST_COLUMN_PROGNOSIS] = item.name;
    object.editable = item.editable;
    object.group = item.group;
    object.value_type = item.value_type;

    item.values?.forEach((value: any) => {
      const foundValue =
        initialValues?.length &&
        initialValues.find((item: any) => value.id === item.name);

      if (foundValue) {
        object[value.date] = foundValue;
      } else {
        object[value.date] = { value: _setValue(value.value), name: value.id };
      }

      if (value.comments) {
        value.comments.forEach((comment: any) => {
          comments.push({ ...comment, key: value.date });
        });
      }
    });

    object.comments = comments;

    mainArray.push(object);
  });

  return mainArray;
};

export const setInitialValuesForTable = (
  data: any,
  profileId?: number | null,
  forecastId?: number | null
) => {
  let object: any = {};

  data?.forEach((item: any) => {
    const forecastValues = localStorage.getItem(
      `forecastValues_${profileId}_${forecastId}`
    );
    const initialValues = forecastValues && JSON.parse(forecastValues);

    for (let elItem in item) {
      if (item[elItem]?.name) {
        const foundValue =
          initialValues?.length &&
          initialValues.find(
            (initItem: any) => initItem.name === item[elItem]?.name
          );

        if (foundValue) {
          object[item[elItem].name] = foundValue.value;
        } else {
          object[item[elItem].name] = _setValue(item[elItem].value);
        }
      }
    }

    if (item.children?.length) {
      item.children.forEach((child: any) => {
        for (let elChild in child) {
          if (child[elChild]?.name) {
            const foundValue =
              initialValues?.length &&
              initialValues.find(
                (initItem: any) => initItem.name === child[elChild]?.name
              );

            if (foundValue) {
              object[child[elChild].name] = foundValue.value;
            } else {
              object[child[elChild].name] = _setValue(child[elChild].value);
            }
          }
        }

        if (child.children?.length) {
          child.children.forEach((childItem: any) => {
            for (let elChildItem in childItem) {
              if (childItem[elChildItem]?.name) {
                const foundValue =
                  initialValues?.length &&
                  initialValues.find(
                    (initItem: any) =>
                      initItem.name === childItem[elChildItem]?.name
                  );

                if (foundValue) {
                  object[childItem[elChildItem].name] = foundValue.value;
                } else {
                  object[childItem[elChildItem].name] = _setValue(
                    childItem[elChildItem].value
                  );
                }
              }
            }
          });
        }
      });
    }
  });

  return object;
};

const _setValue = (value: any) => {
  if (value === null) return "";
  if (value === "null|0") return 0;
  return value;
};

export const setChangedValues = (
  values: ChangedValuesType,
  profileId?: number,
  forecastId?: number
) => {
  const forecastValues = localStorage.getItem(
    `forecastValues_${profileId}_${forecastId}`
  );
  const prevValues = forecastValues && JSON.parse(forecastValues);

  const findValue =
    prevValues?.length &&
    prevValues.find((element: any) => element.name === values.name);

  if (findValue) {
    const filteredPrevValues = prevValues.filter(
      (item: any) => item.name !== findValue.name
    );
    return [...filteredPrevValues, values];
  }
  return prevValues ? [...prevValues, values] : [values];
};

export const resetChangedValues = (
  name: number,
  profileId?: number,
  forecastId?: number
) => {
  const forecastValues = localStorage.getItem(
    `forecastValues_${profileId}_${forecastId}`
  );
  const prevValues = forecastValues && JSON.parse(forecastValues);

  const findValue =
    prevValues?.length &&
    prevValues?.find((element: any) => element.name === name);

  if (findValue) {
    const filteredPrevValues = prevValues.filter(
      (item: any) => item.name !== findValue.name
    );
    localStorage.setItem(
      `forecastValues_${profileId}_${forecastId}`,
      JSON.stringify(filteredPrevValues)
    );
  }
};

export const usersTableColumns = ["name", "email", "hotel", "role", "status"];

export const hotelsTableColumns = ["name", "country"];

export const countriesTableColumns = ["name"];

export const prognosisListTableColumns = ["name", "hotel", "status"];

export const constantsTableColumns = ["name", "value"];

export const hotelSettingsFBTableColumns = ["F&B Split", "AcName", "Split"];

export const hotelSettingsICETableColumns = ["ICE Split", "AcName", "Split"];

const recursiveSearch = (array: any, searchKey: any, results = []) => {
  const resultArray: any = results;
  array.forEach((item: any) => {
    if (item.parameters === searchKey) {
      resultArray.push(item);
    } else if (item.parameters !== searchKey && item.children?.length) {
      recursiveSearch(item.children, searchKey, resultArray);
    }
  });
  return resultArray;
};

export const onFilterParameters = ({
  data,
  valueFilter,
}: {
  data: any;
  valueFilter: string;
}) => (valueFilter ? recursiveSearch(data, valueFilter) : data);

export const setChangedSettings = (values: ChangedValuesType) => {
  const hotelSettings = localStorage.getItem(`hotelSettings`);
  const prevValues = hotelSettings && JSON.parse(hotelSettings);

  const findValue =
    prevValues?.length &&
    prevValues.find((element: any) => element.name === values.name);

  if (findValue) {
    const filteredPrevValues = prevValues.filter(
      (item: any) => item.name !== findValue.name
    );
    return [...filteredPrevValues, values];
  }
  return prevValues ? [...prevValues, values] : [values];
};

export const setInitialValuesForSettings = (data: any) => {
  let object: any = {};

  data?.forEach((item: any) => {
    const settingsValues = localStorage.getItem("hotelSettings");
    const initialValues = settingsValues && JSON.parse(settingsValues);

    for (let elItem in item) {
      if (item[elItem]?.name) {
        const foundValue =
          initialValues?.length &&
          initialValues.find(
            (initItem: any) => initItem.name === item[elItem]?.name
          );

        if (foundValue) {
          object[item[elItem].name] = foundValue.value;
        } else {
          object[item[elItem].name] = item[elItem].value;
        }
      }
    }
  });

  return object;
};
