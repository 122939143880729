import React, { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import Modal from "../components/Modal/Modal";

import { ROLES } from "../../helpers/constants";
import { logout } from "../../redux/reducers/user";
import { StateType } from "../../redux/store";

import { setForceUpdate } from "../../redux/reducers/forecasts";

const TheHeaderDropdown: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile, role } = useSelector((state: StateType) => state.user);
  const { forecast, isUpdatedForecast } = useSelector(
    (state: StateType) => state.forecasts
  );

  const [modal, setModal] = useState(false);

  const onLogout = () => {
    const forecastValues = localStorage.getItem(
      `forecastValues_${profile?.id}_${forecast?.id}`
    );
    const values = forecastValues && JSON.parse(forecastValues);
    values?.length ? setModal(!modal) : dispatch(logout());
  };

  return (
    <CDropdown inNav className="c-header-nav-items mx-2 h-100">
      <CDropdownToggle className="c-header-nav-link pb-0 h-100" caret={false}>
        {profile?.name}
      </CDropdownToggle>

      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>{profile?.name}</strong>
        </CDropdownItem>

        <CDropdownItem onClick={() => history.push("/admin/profile")}>
          <CIcon name="cil-user" className="mfe-2" />
          Profile
        </CDropdownItem>

        {role !== ROLES.INPUT_MAKER && (
          <CDropdownItem onClick={() => history.push("/admin/users")}>
            <CIcon name="cil-people" className="mfe-2" />
            Users
          </CDropdownItem>
        )}

        {role !== ROLES.INPUT_MAKER && role !== ROLES.APPROVER && (
          <CDropdownItem onClick={() => history.push("/admin/counries")}>
            <CIcon name="cil-globe-alt" className="mfe-2" />
            Countries
          </CDropdownItem>
        )}

        <CDropdownItem onClick={() => history.push("/admin/hotels")}>
          <CIcon name="cil-house" className="mfe-2" />
          Hotels
        </CDropdownItem>

        <CDropdownItem onClick={() => history.push("/admin/forecast")}>
          <CIcon name="cil-chart-pie" className="mfe-2" />
          Forecast
        </CDropdownItem>

        <CDropdownItem onClick={() => history.push("/admin/notifications")}>
          <CIcon name="cil-bell" className="mfe-2" />
          Notifications
        </CDropdownItem>

        <CDropdownItem divider />
        <CDropdownItem onClick={onLogout}>
          <CIcon name="cil-account-logout" className="mfe-2" />
          Log out
        </CDropdownItem>
      </CDropdownMenu>

      <Modal
        title="Attention"
        modal={modal}
        toggle={() => setModal(!modal)}
        onSubmit={() => dispatch(setForceUpdate(true))}
        textSaveButton="OK"
        withCancel={true}
      >
        <div className="text-center">
          You have unsaved data. Do you want to update and save your forecast
          now?
        </div>
      </Modal>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
