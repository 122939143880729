import { AxiosResponse } from "axios";
import { instance, headers } from "./index";
import {NotificationType} from "../types/notificationsCenter";

const notificationsCenterApi = {
  getNotifications: async (values: any) => {
    const requestData = headers();
    const { data }: AxiosResponse<{ data: Array<NotificationType> }> = await instance.get("/notificationsCenter", {
      ...requestData,
      ...{ params: values },
    });
    return data;
  }
};

export default notificationsCenterApi;
