import { AxiosResponse } from "axios";
import { instance, headers } from "./index";
import { CountryType } from "../types/countries";

const countriesApi = {
  getCountries: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<CountryType> }> = await instance.get(
      "/countries",
      headers()
    );
    return data;
  },

  getCountryById: async (id: number) => {
    const { data }: AxiosResponse<{ data: CountryType }> = await instance.get(
      `/countries/${id}`,
      headers()
    );
    return data;
  },

  createCountry: async (values: any) => {
    const { data }: AxiosResponse<{ data: CountryType }> = await instance.post(
      `/countries`,
      values,
      headers()
    );
    return data;
  },

  updateCountry: async ({
    id,
    values,
  }: {
    id: number;
    values: CountryType;
  }) => {
    const { data }: AxiosResponse<{ data: CountryType }> = await instance.put(
      `/countries/${id}`,
      values,
      headers()
    );
    return data;
  },

  deleteCountry: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `/countries/${id}`,
      headers()
    );
    return data;
  },
};

export default countriesApi;
