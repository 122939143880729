import { instance, headers } from "./index";
import { UpdateProfileType } from "../types/user";
import { filterHotelsIds } from "./users";
import { ChangePasswordType, ConfirmPasswordType } from "../types/auth";

const userApi = {
  getProfile: async () => {
    const { data } = await instance.get("/users/profile", headers());
    return data;
  },

  getProfileByCode: async (code: string) => {
    const { data } = await instance.get(`/auth/profile/${code}`, headers());
    return data;
  },

  updateProfile: async (value: UpdateProfileType | {}) => {
    let userData = filterHotelsIds(JSON.parse(JSON.stringify(value)));
    const { data } = await instance.put(
      "/users/update-profile",
      userData,
      headers()
    );
    return data;
  },

  changePassword: async (values: ChangePasswordType | {}) => {
    const { data } = await instance.post("/users/change-password", values);
    return data;
  },

  signOut: async () => {
    const { data } = await instance.post("/auth/logout", headers());
    return data;
  },
};

export default userApi;
