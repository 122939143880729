import React from "react";
import { useParams } from "react-router";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CRow,
} from "@coreui/react";

type PropsType = {
  title?: string;
  submitText?: string;
  onSubmit: (e: any) => void;
  onCancel: () => void;
  disabled?: boolean;
  hasCancel?: boolean;
  isLoading?: boolean;
};

type ParamsTypes = {
  id: string;
};

const FormMain: React.FC<PropsType> = ({
  title,
  submitText,
  onSubmit,
  onCancel,
  children,
  disabled,
  hasCancel = true,
  isLoading = false,
}) => {
  const { id } = useParams<ParamsTypes>();

  return (
    <CRow>
      <CCol>
        <form onSubmit={onSubmit}>
          <CCard>
            {title && (
              <CCardHeader>
                <h2>{title}</h2>
              </CCardHeader>
            )}

            <CCardBody>{children}</CCardBody>

            <CCardFooter>
              {!disabled && (
                <CButton type="submit" color="primary" className="float-right">
                  {isLoading ? (
                    <div
                      className="spinner-border"
                      style={{
                        width: "17px",
                        height: "17px",
                        marginRight: "8px",
                      }}
                    >
                      {" "}
                    </div>
                  ) : (
                    ""
                  )}
                  {submitText ?? (id && +id ? "Save" : "Create")}
                </CButton>
              )}{" "}
              {hasCancel ? (
                <CButton
                  type="button"
                  color="danger"
                  onClick={onCancel}
                  className="float-left"
                >
                  {disabled ? "Back" : "Cancel"}
                </CButton>
              ) : (
                ""
              )}
            </CCardFooter>
          </CCard>
        </form>
      </CCol>
    </CRow>
  );
};

export default FormMain;
