import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CRow,
} from "@coreui/react";
import { useFormik } from "formik";
import * as yup from "yup";

import Input from "../../common/Input/Input";

import { createPassword, signIn } from "../../../redux/actions/auth";
import { ConfirmPasswordType } from "../../../types/auth";
import { getProfileByCode } from "../../../redux/actions/user";
import { logout } from "../../../redux/reducers/user";
import { StateType } from "../../../redux/store";

type ParamsTypes = {
  code: string;
};

function useDidUpdateEffect(fn: any, inputs: any) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, inputs);
}

const ConfirmPasswordPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { code } = useParams<ParamsTypes>();
  const { profile, invitedProfile, isLoading } = useSelector(
    (state: StateType) => state.user
  );

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(8, "Too Short! The password must be at least 8 characters")
      .required("Password is required"),
    password_confirmation: yup
      .string()
      .required("Password confirmation is required")
      .when("password", {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref("password")], "Both password need to be the same"),
      }),
  });

  useEffect(() => {
    dispatch(getProfileByCode(code));
  }, [dispatch]);

  useDidUpdateEffect(() => {
    if (!isLoading && profile && invitedProfile) {
      dispatch(logout());
    }

    if (!isLoading && !invitedProfile) {
      history.push("/");
    }
  }, [invitedProfile, isLoading]);

  const formik = useFormik({
    initialValues: { password: "", password_confirmation: "", invite_code: "" },
    validationSchema: validationSchema,
    onSubmit: (values: ConfirmPasswordType) => {
      values.invite_code = code;
      // @ts-ignore
      dispatch(createPassword(values))
        // @ts-ignore
        .then(
          (res: any) =>
            !res.error &&
            dispatch(
              signIn({
                email: res.payload.data.email,
                password: values.password,
              })
            )
              // @ts-ignore
              .then((res: any) => !res.error && history.push("/"))
        );
    },
  });

  return (
    <>
      {!isLoading && invitedProfile && (
        <div className="c-app c-default-layout flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md="8">
                <CCard className="p-4">
                  <CCardBody>
                    <CForm>
                      <h1>Create password</h1>
                      <p className="text-muted">
                        Welcome on board, <b>{invitedProfile?.email}</b>, please
                        enter your password
                      </p>
                      <CRow>
                        <CCol>
                          <Input
                            name="password"
                            label="Password"
                            type="password"
                            value={formik.values.password}
                            handleChange={formik.handleChange}
                            error={
                              formik.touched.password &&
                              Boolean(formik.errors.password)
                            }
                            helperText={
                              formik.touched.password && formik.errors.password
                            }
                          />
                          <Input
                            name="password_confirmation"
                            label="Confirm password"
                            type="password"
                            value={formik.values.password_confirmation}
                            handleChange={formik.handleChange}
                            error={
                              formik.touched.password_confirmation &&
                              Boolean(formik.errors.password_confirmation)
                            }
                            helperText={
                              formik.touched.password_confirmation &&
                              formik.errors.password_confirmation
                            }
                          />
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol xs="6">
                          <CButton
                            color="primary"
                            className="px-4"
                            onClick={formik.handleSubmit}
                          >
                            Send
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      )}
    </>
  );
};

export default ConfirmPasswordPage;
