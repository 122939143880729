import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import {
  SignInType,
  ResetPasswordType,
  ConfirmPasswordType, ForgotPasswordType,
} from "../../types/auth";

export const signIn = createAsyncThunk(
  "auth/sign-in",
  async (values: SignInType, thunkAPI) => {
    try {
      const data = await api.auth.signIn(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgot-password",
  async (values: ForgotPasswordType, thunkAPI) => {
    try {
      return await api.auth.forgotPassword(values);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async (values: ResetPasswordType, thunkAPI) => {
    try {
      const data = await api.auth.resetPassword(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createPassword = createAsyncThunk(
  "auth/create-password",
  async (values: ConfirmPasswordType, thunkAPI) => {
    try {
      return await api.auth.createPassword(values);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
