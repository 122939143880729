import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { StateType } from "../../redux/store";

type PrivateRouteProps = {
  exact: boolean;
  path: string;
  component: React.ComponentType<any>;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const profile = useSelector((state: StateType) => state.user.profile);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (profile) {
          return <Component {...props} />;
        }

        return <Redirect to="/" />;
      }}
    />
  );
};

export default PrivateRoute;
