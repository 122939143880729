import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { UpdateProfileType } from "../../types/user";

export const getProfile = createAsyncThunk("profile/getProfile", async () => {
  try {
    const { data } = await api.user.getProfile();
    return data;
  } catch (err) {
    localStorage.removeItem("token");
  }
});

export const getProfileByCode = createAsyncThunk(
  "profile/getProfileByCode",
  async (code:string, thunkAPI) => {
  try {
    const { data } = await api.user.getProfileByCode(code);
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (values: UpdateProfileType | {}, thunkAPI) => {
    try {
      return await api.user.updateProfile(values);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "profile/changePassword",
  async (values: UpdateProfileType | {}, thunkAPI) => {
    try {
      return await api.user.changePassword(values);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const signOut = createAsyncThunk("profile/logout", async () => {
  try {
    const data = await api.user.signOut();
    return data;
  } catch (err) {
    return err.response.data;
  }
});
