import { useEffect } from "react";
import { CCol, CRow } from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import { getUserById } from "../../../../redux/actions/users";
import { useParams } from "react-router-dom";
import UserForm from "./UserForm";
import { StateType } from "../../../../redux/store";

type ParamsTypes = {
  id: string;
};

const UserEdit = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: StateType) => state.users);
  const { id } = useParams<ParamsTypes>();

  useEffect(() => {
    dispatch(getUserById(Number(id)));
  }, [dispatch]);

  return (
    <CRow>
      <CCol lg={12}>
        <UserForm user={user} />
      </CCol>
    </CRow>
  );
};

export default UserEdit;
