import { createSlice } from "@reduxjs/toolkit";
import { createUser, deleteUser, updateUser } from "../actions/users";
import { createPassword, forgotPassword, resetPassword } from "../actions/auth";
import {
  changePassword,
  getProfileByCode,
  updateProfile,
} from "../actions/user";
import { saveValueComment } from "../actions/forecasts";
import {
  getHotelSettings,
  updateHotelSettings,
} from "../actions/hotelSettings";

type initialStateType = {
  notification: {
    show: boolean;
    type: string;
    title: string;
    text: string;
    autohide: number;
  };
  sidebarShow: boolean | "" | "responsive" | undefined;
  maximusView: boolean;
  comments: Array<any>;
  activeElement: any;
};

const initialState: initialStateType = {
  notification: { show: false, type: "", title: "", text: "", autohide: 5000 },
  sidebarShow: "responsive",
  comments: [],
  activeElement: null,
  maximusView: false,
};

const additionsSlice = createSlice({
  name: "additions",
  initialState,
  reducers: {
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    setComment(state, { payload }) {
      state.comments.push(payload);
    },
    setActiveElement(state, { payload }) {
      state.activeElement = payload;
    },
  },
  extraReducers: {
    [createUser.fulfilled.toString()]: (state) => {
      state.notification.show = true;
      state.notification.type = "success";
      state.notification.title = "User successfully invited";
    },
    [updateUser.fulfilled.toString()]: (state) => {
      state.notification.show = true;
      state.notification.type = "success";
      state.notification.title = "User successfully updated";
    },
    [deleteUser.fulfilled.toString()]: (state) => {
      state.notification.show = true;
      state.notification.type = "success";
      state.notification.title = "User successfully deleted";
    },
    [createPassword.fulfilled.toString()]: (state) => {
      state.notification.show = true;
      state.notification.type = "success";
      state.notification.title = "Password successfully created";
    },
    [createPassword.rejected.toString()]: (state, { payload }) => {
      if (typeof payload.message === "string") {
        state.notification.show = true;
        state.notification.type = "danger";
        state.notification.title = payload.message;
      }
    },
    // [forgotPassword.fulfilled.toString()]: (state, { payload }) => {
    //   state.notification.show = true;
    //   state.notification.type = 'success';
    //   state.notification.title = 'Reset password link was sent to your email';
    // },
    [resetPassword.fulfilled.toString()]: (state) => {
      state.notification.show = true;
      state.notification.type = "success";
      state.notification.title = "Password was successfully reset";
    },
    [resetPassword.rejected.toString()]: (state, { payload }) => {
      state.notification.show = true;
      state.notification.type = "danger";
      state.notification.title = payload.message;
    },
    [updateProfile.fulfilled.toString()]: (state, { payload }) => {
      state.notification.show = true;
      state.notification.type = "success";
      state.notification.title = payload.message;
    },
    [changePassword.fulfilled.toString()]: (state, { payload }) => {
      state.notification.show = true;
      state.notification.type = "success";
      state.notification.title = payload.message;
    },
    [getProfileByCode.rejected.toString()]: (state, { payload }) => {
      state.notification.show = true;
      state.notification.type = "danger";
      state.notification.title = payload.message;
    },
    [saveValueComment.fulfilled.toString()]: (state, { payload }) => {
      state.notification.show = true;
      state.notification.type = "success";
      state.notification.title = payload.message;
    },
    [updateHotelSettings.rejected.toString()]: (state, { payload }) => {
      state.notification.show = true;
      state.notification.type = "danger";
      state.notification.title = payload.message.message;
    },
  },
});

export const {
  setStateValue,
  setComment,
  setActiveElement,
} = additionsSlice.actions;

export default additionsSlice.reducer;
