import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import LoginPage from "./LoginPage";
import ForgotPasswordPage from "./ForgotPasswordPage";
import ConfirmPasswordPage from "./ConfirmPasswordPage";
import ResetPasswordPage from "./ResetPasswordPage";

const AuthPage: React.FC = () => (
  <Switch>
    <Route path="/auth/login" exact render={() => <LoginPage />} />

    <Route
    path="/auth/invite/:code"
    exact
    render={() => <ConfirmPasswordPage />}
    />

    <Route
      path="/auth/confirm-password"
      exact
      render={() => <ConfirmPasswordPage />}
    />

    <Route
      path="/auth/forgot-password"
      exact
      render={() => <ForgotPasswordPage />}
    />

    <Route
      path="/auth/reset-password/:token"
      exact
      render={() => <ResetPasswordPage />}
    />

    <Redirect from="/" to="/auth/login" />
  </Switch>
);

export default AuthPage;
