import { createSlice } from "@reduxjs/toolkit";

import { forgotPassword, signIn } from "../actions/auth";

type InitialStateType = {
  token: string | null;
  user: boolean | null;
  isLoading: boolean;
  errors: boolean | null;
  message: string | null;
};

const initialState: InitialStateType = {
  token: null,
  user: null,
  isLoading: false,
  errors: null,
  message: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    ressetAuth() {
      return initialState;
    },
    setError(state, { payload }) {
      return {
        ...state,
        errors: payload,
      };
    },
  },
  extraReducers: {
    [signIn.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [signIn.fulfilled.toString()]: (state, { payload }) => {
      localStorage.setItem("token", payload.access_token);
      state.token = payload.access_token;
      state.user = true;
      state.isLoading = false;
    },
    [signIn.rejected.toString()]: (state, { payload }) => {
      state.errors = payload?.error ? payload.error : "Server Error";
      state.isLoading = false;
    },
    [forgotPassword.pending.toString()]: (state, { payload }) => {
      state.message = null;
      state.errors = null;
    },
    [forgotPassword.fulfilled.toString()]: (state, { payload }) => {
      state.message = payload.message;
    },
    [forgotPassword.rejected.toString()]: (state, { payload }) => {
      state.errors = payload.message;
    },
  },
});

export const { setStateValue, ressetAuth, setError } = authSlice.actions;

export default authSlice.reducer;
