import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { StateType } from "../redux/store";

const DefaultRoute: React.FC = () => {
  const { profile } = useSelector((state: StateType) => state.user);
  let path;

  if (profile) {
    path = "admin";
  } else {
    path = "auth/login";
  }

  return <Redirect to={path} />;
};

export default DefaultRoute;
