import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { HotelType, HotelInListType } from "../../types/hotels";

export const getHotels = createAsyncThunk("hotels/get-hotels", async () => {
  try {
    const {
      data,
    }: { data: Array<HotelInListType> } = await api.hotels.getHotels();
    return data;
  } catch (err) {}
});

export const getHotelById = createAsyncThunk(
  "hotels/get-hotel-by-id",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: HotelType } = await api.hotels.getHotelById(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createHotel = createAsyncThunk(
  "hotels/create-hotel",
  async (values: HotelType, thunkAPI) => {
    try {
      const { data }: { data: HotelType } = await api.hotels.createHotel(
        values
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateHotel = createAsyncThunk(
  "hotels/update-hotel",
  async (values: { id: number; values: HotelType }, thunkAPI) => {
    try {
      const { data }: { data: HotelType } = await api.hotels.updateHotel(
        values
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteHotel = createAsyncThunk(
  "hotels/delete-hotel",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: string } = await api.hotels.deleteHotel(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
