import { CCol, CRow } from "@coreui/react";
import UserForm from "./UserForm";
import { useSelector } from "react-redux";
import { StateType } from "../../../../redux/store";

const UserCreate = () => {
  const { newUser } = useSelector((state: StateType) => state.users);
  return (
    <CRow>
      <CCol lg={12}>
        <UserForm user={newUser} />
      </CCol>
    </CRow>
  );
};

export default UserCreate;
