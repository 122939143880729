import React, { ChangeEvent } from "react";
import Select from "react-select";
import { CFormGroup, CLabel, CInvalidFeedback } from "@coreui/react";
import { FormikErrors } from "formik";

type SelecterPropsType = {
  name: string;
  options: Array<any>;
  label?: string;
  placeholder?: string;
  onChange?: (e: ChangeEvent<any>) => void;
  initialValue?: any;
  value?: any;
  isMulti?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?:
    | string
    | false
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
};

const customStyles = {
  option: (
    provided: any,
    { isFocused, isSelected }: { isFocused: any; isSelected: any }
  ) => ({
    ...provided,
    backgroundColor: isFocused || isSelected ? "#a9d6db" : null,
    cursor: "pointer",
  }),
  control: (
    provided: any,
    { isFocused, isSelected }: { isFocused: any; isSelected: any }
  ) => ({
    ...provided,
    cursor: "pointer",
    boxShadow:
      isFocused || isSelected ? "0 0 0.2rem rgb(79, 170, 182)" : "none",
    border: isFocused || isSelected ? "1px solid #a9d6db" : "1px solid #ababab",
    color: "inherit",
    borderRadius: "0.25rem",
    outlineOffset: isFocused || isSelected ? 3 : null,
    "&:hover": {
      borderColor: "none",
    },
  }),
  indicatorContainer: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 1 : 1;
    const color = state.isDisabled ? "#004bc1" : "#004bc1";

    return { ...provided, opacity, color };
  },
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 1 : 1;
    const color = state.isDisabled ? "#000" : "inherit";

    return { ...provided, opacity, color };
  },
  menu: (provided: any) => ({
    ...provided,
    zIndex: 2,
    "&:-webkit-scrollbar-track": {},
  }),
  menuList: (provided: any) => ({
    ...provided,
    "&:-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
  }),
};

const SelectorCustom: React.FC<SelecterPropsType> = ({
  name,
  options,
  placeholder,
  disabled,
  error,
  label,
  onChange,
  value,
  isMulti,
  helperText,
}) => {
  const handleChange = (selectedOption: any) => {
    if (isMulti) {
      onChange && onChange(selectedOption);
    } else {
      onChange && onChange(selectedOption.value);
    }
  };

  return (
    <CFormGroup>
      {label && <CLabel htmlFor={name}>{label}</CLabel>}

      <Select
        className={`react-select-container ${error ? "is-invalid" : ""}`}
        classNamePrefix="react-select"
        //@ts-ignore
        styles={customStyles}
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        options={options}
        isDisabled={disabled}
        error={error}
        name={name}
        id={name}
        isMulti={isMulti}
        isSearchable={true}
        onBlur={(e: any) => {
          e.preventDefault();
        }}
        noOptionsMessage={() => "No found"}
      />

      {error && (
        <CInvalidFeedback className="text-danger">
          {helperText}
        </CInvalidFeedback>
      )}
    </CFormGroup>
  );
};

export default SelectorCustom;
