import axios from "axios";
import auth from "./auth";
import user from "./user";
import users from "./users";
import hotels from "./hotels";
import countries from "./countries";
import constants from "./constants";
import forecasts from "./forecasts";
import userRole from "./userRole";
import parameters from "./parameters";
import notificationsCenter from "./notificationsCenter";
import hotelSettings from "./hotelSettings";

export const SERVER_HOST = `${process.env.REACT_APP_SERVER_HOST}/api/v1`;

export const setHeader = () => {
  let token;

  if (typeof window !== "undefined") {
    token = localStorage.getItem("token");
  }

  return {
    Accept: "application/json",
    Authorization: token && `Bearer ${token}`,
  };
};

export const instance = axios.create({
  baseURL: SERVER_HOST,
  headers: setHeader(),
});

instance.interceptors.response.use(
  (response) => {
    // const localStor = localStorage.getItem("token");
    // const token = localStor && JSON.parse(localStor);
    // if (token) {
    //   response.headers.Authorization = `Bearer ${token}`;
    // }

    return response;
  },
  (error) => {
    if (
      error.request.status === 401 &&
      !window.location.href.includes("/auth")
    ) {
      window.location.href = `${window.location.origin}/auth/login`;
    }

    if (error.request.status === 500 || error.request.status === 429) {
      if (!localStorage.getItem("token")) {
        setTimeout(() => {
          window.location.href = `${window.location.origin}`;
        }, 2000);
      }
    }

    return Promise.reject(error);
  }
);

export const headers = (params?: any) => ({
  headers: setHeader(),
  params,
});

const api = {
  auth,
  user,
  users,
  hotels,
  countries,
  constants,
  forecasts,
  userRole,
  parameters,
  notificationsCenter,
  hotelSettings,
};

export default api;
