import { ROLES } from "./constants";
import { navBar, navBarInputMaker } from "../views/layout/_nav";

export const getNavbarElements = (role: string) => {
  switch (role) {
    case ROLES.INPUT_MAKER:
      return navBarInputMaker;
    case ROLES.APPROVER:
      return [navBar[0], ...navBar.slice(2, 5)];
    case ROLES.COMPANY:
      return navBar;
    default:
      return navBar;
  }
};
