export const ROLES = {
  ADMIN: "admin",
  COMPANY: "company",
  APPROVER: "approver",
  INPUT_MAKER: "inputmaker",
};

export const STATUSES_PROGNOSIS = {
  CREATED: "Created",
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  ARCHIVED: "Archived",
};
// Created
// Pending
// Approved
// Rejected
// Archived

export const STATUSES_USER = {
  ACTIVE: "active",
  PENDING: "pending",
  BLOCKED: "blocked",
};

export const SOURCES = {
  MANUAL: "manual",
  FORMULA: "formula",
  CONSTANT: "constant",
  DUETTO: "duetto",
  TIMEPLANE: "timeplan",
};

export const FIRST_COLUMN_PROGNOSIS = "parameters";

export const PERCENT_VALUE = "percent";

export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const COMMENT_DATETIME_FORMAT = "DD/MM/YYYY";
export const FORECAST_MONTH = "MMM Y";
