import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import {
  HotelSettingsType,
  HotelSettingsGetParamsType,
} from "../../types/hotelSettings";

export const getHotelSettings = createAsyncThunk(
  "hotelSettings/get-hotelSettings",
  async (params: HotelSettingsGetParamsType, thunkAPI) => {
    try {
      const {
        data,
      }: { data: Array<HotelSettingsType> } = await api.hotelSettings.getHotelSettings(
        params
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateHotelSettings = createAsyncThunk(
  "hotelSettings/update-hotelSettings",
  async (values: any, thunkAPI) => {
    try {
      const {data}: { data: Array<any>; } = await api.hotelSettings.updateHotelSettings(values);
      return data;
    } catch (err) {
      console.log(err.response.data);
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
