import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import Input from "../../../common/Input/Input";
import Form from "../../../components/Form/Form";
import SelectorCustom from "../../../common/SelectorCustom/SelectorCustom";

import { ROLES } from "../../../../helpers/constants";
import { createUser, updateUser } from "../../../../redux/actions/users";
import { clearUser } from "../../../../redux/reducers/users";
import { UserType } from "../../../../redux/reducers/users";
import { getUserRoles } from "../../../../redux/actions/userRole";
import { getHotels } from "../../../../redux/actions/hotels";
import { StateType } from "../../../../redux/store";

const initialValues = {
  name: "",
  email: "",
  user_role_id: 0,
  company_id: 0,
  active: false,
  hotels: [],
};

type PropsType = {
  user: UserType | null;
};

const UserForm: React.FC<PropsType> = ({ user }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(
    user?.data.id ? user.data : initialValues
  );
  const { role } = useSelector((state: StateType) => state.user);
  const { userRoles } = useSelector((state: StateType) => state.userRole);
  const { hotels } = useSelector((state: StateType) => state.hotels);

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required"),
    user_role_id: yup.number().moreThan(0, "Role is required"),
    hotels: yup.array().when("user_role_id", {
      is: 4,
      then: yup.array().min(1, "Please choose at least one hotel"),
    }),
  });

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    isInitialValid: false,
    onSubmit: (values: typeof initialValues) => onSubmit(values),
  });

  useEffect(() => {
    dispatch(getUserRoles());
    dispatch(getHotels());

    return () => {
      dispatch(clearUser());
    };
  }, [dispatch]);

  useEffect(() => {
    user?.data.id && formik.setValues(user?.data);
  }, [user?.data.id]);

  const onSubmit = (values: typeof initialValues) => {
    if (user?.data.id) {
      // @ts-ignore
      dispatch(updateUser({ id: user.data.id, data: values })).then(
        (res: any) => !res.error && history.goBack()
      );
    } else {
      // @ts-ignore
      dispatch(createUser(values)).then(
        (res: any) => !res.error && history.goBack()
      );
    }
  };

  const roleOptions = userRoles?.map((role: any) => ({
    value: role.id,
    label: role.name,
  }));

  const handleHotelsChange = (items: any) => {
    formik.setFieldValue("hotels", items);
  };

  const handleRoleChange = (item: any) => {
    formik.setFieldValue("user_role_id", item);
  };

  return (
    <Form
      title={user?.data?.id ? `Edit user` : "Invite user"}
      submitText={user?.data?.id ? `Save` : "Invite"}
      onSubmit={formik.handleSubmit}
      onCancel={history.goBack}
      disabled={false}
    >
      <SelectorCustom
        name="user_role_id"
        label="Role"
        options={
          roleOptions && roleOptions?.length
            ? role === ROLES.APPROVER
              ? roleOptions.slice(3, 4)
              : role === ROLES.COMPANY
              ? roleOptions.slice(2, 4)
              : roleOptions.slice(1, 4)
            : []
        }
        value={roleOptions.filter(
          (option) => option.value === formik.values.user_role_id
        )}
        onChange={handleRoleChange}
        error={
          (formik.touched.user_role_id &&
            Boolean(formik.errors.user_role_id)) ||
          Boolean(user?.errors?.message?.original?.user_role_id)
        }
        helperText={
          (formik.touched.user_role_id && formik.errors.user_role_id) ||
          (user?.errors?.message?.original?.user_role_id ?? null)
        }
        disabled={false}
      />
      <Input
        name="name"
        label="Name"
        value={formik.values.name}
        handleChange={formik.handleChange}
        error={
          (formik.touched.name && Boolean(formik.errors.name)) ||
          Boolean(user?.errors?.message?.original?.name)
        }
        helperText={
          (formik.touched.name && formik.errors.name) ||
          (user?.errors?.message?.original?.name ?? null)
        }
        disabled={false}
      />

      <Input
        name="email"
        label="Email"
        value={formik.values.email}
        handleChange={formik.handleChange}
        error={
          (formik.touched.email && Boolean(formik.errors.email)) ||
          Boolean(user?.errors?.message?.original?.email)
        }
        helperText={
          (formik.touched.email && formik.errors.email) ||
          (user?.errors?.message?.original?.email ?? null)
        }
        disabled={false}
      />
      {+formik.values.user_role_id === 4 && (
        <SelectorCustom
          name="hotels"
          label="Hotels"
          isMulti
          options={hotels.map((hotel: any) => ({
            label: hotel.name,
            value: hotel.id,
          }))}
          value={formik.values.hotels?.map((hotel: any) => ({
            label: hotel.name ?? hotel.label,
            value: hotel.id ?? hotel.value,
          }))}
          onChange={handleHotelsChange}
          error={formik.touched.hotels && Boolean(formik.errors.hotels)}
          helperText={formik.touched.hotels && formik.errors.hotels}
          disabled={false}
        />
      )}
    </Form>
  );
};

export default UserForm;
