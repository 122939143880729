import { createSlice } from "@reduxjs/toolkit";

import { getUserRoles } from "../actions/userRole";
import { UserRole } from "../../types/userRole";

type InitialStateType = {
  userRoles: Array<UserRole> | [];
  isLoading: boolean;
  errors: boolean | null;
};

const initialState: InitialStateType = {
  userRoles: [],
  isLoading: false,
  errors: null,
};

const userRoleSlice = createSlice({
  name: "userRole",
  initialState,
  reducers: {},
  extraReducers: {
    [getUserRoles.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getUserRoles.fulfilled.toString()]: (state, { payload }) => {
      state.userRoles = payload;
      state.isLoading = false;
    },
    [getUserRoles.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export default userRoleSlice.reducer;
