import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CRow,
} from "@coreui/react";
import { useFormik } from "formik";
import * as yup from "yup";

import Input from "../../common/Input/Input";

import { setStateValue } from "../../../redux/reducers/additions";
import {forgotPassword} from "../../../redux/actions/auth";
import {StateType} from "../../../redux/store";

const ForgotPasswordPage: React.FC = () => {
  const dispatch = useDispatch();
  const { message, errors } = useSelector((state: StateType) => state.auth);

  const validationSchema = yup.object({
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: validationSchema,
    onSubmit: (values: { email: string }) => {
      dispatch(forgotPassword(values));
    },
  });

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCard className="p-4">
              <CCardBody>
                <CForm>
                  <h1>Forgot password?</h1>
                  <p className="text-muted">Enter your email</p>

                  <CRow>
                    <CCol>
                      <Input
                        type="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        handleChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </CCol>
                  </CRow>
                  { (message || errors) && <CAlert color={errors?'warning':'success'}>{message??errors}</CAlert>}
                  <CRow>
                    <CCol xs="6">
                      <CButton
                        color="primary"
                        className="px-4"
                        onClick={formik.handleSubmit}
                      >
                        Send
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgotPasswordPage;
