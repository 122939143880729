import { AxiosResponse } from "axios";
import { instance, headers, setHeader } from "./index";
import {
  ChangedValuesType,
  ForecastStatusType,
  ForecastType,
  ParametersUpdateStatusType,
  ParametersUpdateLastForecastType,
} from "../types/forecasts";

const forecastsApi = {
  getForecasts: async (params?: any) => {
    const {
      data,
    }: AxiosResponse<{ data: Array<ForecastType> }> = await instance.get(
      "/forecasts",
      headers(params)
    );
    return data;
  },

  getForecastById: async (id: number) => {
    const { data }: AxiosResponse<{ data: ForecastType }> = await instance.get(
      `/forecasts/${id}/${id}`,
      headers()
    );
    return data;
  },

  createForecast: async (values: ForecastType) => {
    const { data }: AxiosResponse<{ data: ForecastType }> = await instance.post(
      "/forecasts",
      values,
      headers()
    );
    return data;
  },

  updateForecast: async ({
    id,
    values,
  }: {
    id: number;
    values: ForecastType;
  }) => {
    const { data }: AxiosResponse<{ data: ForecastType }> = await instance.put(
      `/forecasts/${id}`,
      values,
      headers()
    );
    return data;
  },

  updateForecastValues: async (values: { data: Array<ChangedValuesType> }) => {
    const data: AxiosResponse<any> = await instance.put(
      `/forecastValues`,
      values,
      headers()
    );
    return data;
  },

  deleteConstant: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `/forecasts/${id}`,
      headers()
    );
    return data;
  },

  getForecastStatus: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<ForecastStatusType> }> = await instance.get(
      "/forecastStatuses",
      headers()
    );
    return data;
  },

  updateForecastStatus: async (values: ParametersUpdateStatusType) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.post(
      "/forecasts/status",
      values,
      headers()
    );
    return data;
  },

  updateLastForecast: async (values: ParametersUpdateLastForecastType) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.post(
      "/forecasts/update-latest",
      values,
      headers()
    );
    return data;
  },

  uploadDuetto: async (values: any) => {
    const data: AxiosResponse<any> = await instance.post(
      `/forecastValues/import/duetto`,
      values,
      {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  },

  uploadTimeplan: async (values: { forecastID: number }) => {
    const data: AxiosResponse<any> = await instance.post(
      `/forecastValues/import/timeplan`,
      values,
      headers()
    );
    return data;
  },

  saveValueComment: async (values: any) => {
    const data: AxiosResponse<any> = await instance.post(
      `/forecastValueComments`,
      values,
      headers()
    );
    return data;
  },

  getValueCommentsById: async (id: number) => {
    const data: AxiosResponse<any> = await instance.get(
      `/forecastValueCommentsById/${id}`,
      headers()
    );
    return data;
  },

  getForecastComments: async (id: number) => {
    const data: AxiosResponse<any> = await instance.get(
      `/forecastValueCommentsByForecastId/${id}`,
      headers(``)
    );
    return data;
  },
};

export default forecastsApi;
