import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { CountryType } from "../../types/countries";

export const getCountries = createAsyncThunk(
  "countries/get-countries",
  async () => {
    try {
      const {
        data,
      }: { data: Array<CountryType> } = await api.countries.getCountries();
      return data;
    } catch (err) {}
  }
);

export const getCountryById = createAsyncThunk(
  "countries/get-country-by-id",
  async (id: number, thunkAPI) => {
    try {
      const {
        data,
      }: { data: CountryType } = await api.countries.getCountryById(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createCountry = createAsyncThunk(
  "countries/create-country",
  async (values: CountryType, thunkAPI) => {
    try {
      const { data } = await api.countries.createCountry(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateCountry = createAsyncThunk(
  "countries/update-country",
  async (values: { id: number; values: CountryType }, thunkAPI) => {
    try {
      const { data } = await api.countries.updateCountry(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteCountry = createAsyncThunk(
  "countries/delete-country",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: string } = await api.countries.deleteCountry(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
