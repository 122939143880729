import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { ParameterType } from "../../types/parameters";

export const getParameters = createAsyncThunk(
  "parameters/get-parameters",
  async () => {
    try {
      const {
        data,
      }: { data: Array<ParameterType> } = await api.parameters.getParameters();
      return data;
    } catch (err) {
      return err.response.data;
    }
  }
);
