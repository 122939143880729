import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { UserRole } from "../../types/userRole";

export const getUserRoles = createAsyncThunk(
  "userRole/get_user_roles_list",
  async () => {
    try {
      const {
        data,
      }: { data: Array<UserRole> } = await api.userRole.getUserRoles();
      return data;
    } catch (err) {
      localStorage.removeItem("token");
    }
  }
);
