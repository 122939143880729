import { createSlice } from "@reduxjs/toolkit";
import { getParameters } from "../actions/parameters";

import { ParameterType } from "../../types/parameters";

const initialState: {
  parameters: Array<ParameterType> | [];
  parametersOptions: Array<ParameterType> | [];
  isLoading: boolean;
  errors: any;
} = {
  parameters: [],
  parametersOptions: [],
  isLoading: false,
  errors: null,
};

const parametersSlice = createSlice({
  name: "parameters",
  initialState,
  reducers: {
    setStateParameters(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
  },
  extraReducers: {
    [getParameters.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getParameters.fulfilled.toString()]: (state, { payload }) => {
      state.parameters = payload;
      state.parametersOptions = payload.map((item: any) => ({
        value: item.name,
        label: item.name,
      }));
      state.isLoading = false;
    },
    [getParameters.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export const { setStateParameters } = parametersSlice.actions;

export default parametersSlice.reducer;
