import React from "react";
import { useParams, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CRow,
  CInput,
} from "@coreui/react";
import { useFormik } from "formik";
import * as yup from "yup";

import Input from "../../common/Input/Input";

import { setStateValue } from "../../../redux/reducers/additions";
import {forgotPassword, resetPassword, signIn} from "../../../redux/actions/auth";
import {Link} from "react-router-dom";
const queryString = require('query-string');

type ParamsTypes = {
  token: string;
};

const ResetPasswordPage: React.FC = () => {
  const dispatch = useDispatch();

  let { token } = useParams<ParamsTypes>();
  let query = queryString.parse(useLocation().search);

  const initialValues = {
    token: token,
    email: query.email,
    password: '',
    password_confirmation: '',
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Too Short! The password must be at least 8 characters")
      .required("Password is required"),
    password_confirmation: yup.string().when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("password")], "Both password need to be the same"),
    }),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      // @ts-ignore
      dispatch(resetPassword(values)).then((res: any) => !res.error && dispatch(signIn({email: values.email, password: values.password})));
    },
  });

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCard className="p-4">
              <CCardBody>
                <CForm>
                  <h1>Reset password</h1>
                  <CRow>
                    <CCol>
                      <CInput id="token" name="token" type="hidden" />
                      <CInput id="email" name="email" type="hidden" />

                      {/*<Input
                        type="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        handleChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                        readOnly={true}
                      />*/}

                      <Input
                        name="password"
                        label="Password"
                        type="password"
                        value={formik.values.password}
                        handleChange={formik.handleChange}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                      />

                      <Input
                        name="password_confirmation"
                        label="Confirm password"
                        type="password"
                        value={formik.values.password_confirmation}
                        handleChange={formik.handleChange}
                        error={
                          formik.touched.password_confirmation &&
                          Boolean(formik.errors.password_confirmation)
                        }
                        helperText={
                          formik.touched.password_confirmation &&
                          formik.errors.password_confirmation
                        }
                      />
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol xs="6">
                      <CButton
                        color="primary"
                        className="px-4"
                        onClick={formik.handleSubmit}
                      >
                        Send
                      </CButton>
                    </CCol>
                    <CCol xs="6" className="text-right">
                      <Link to={`/auth/forgot-password`} className="px-0" color="link">
                        Request password reset link again
                      </Link>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ResetPasswordPage;
