import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CToast, CToastBody, CToastHeader, CToaster } from "@coreui/react";

import { StateType } from "../../../redux/store";
import { setStateValue } from "../../../redux/reducers/additions";

const Notification: React.FC = () => {
  const dispatch = useDispatch();
  const { notification } = useSelector((state: StateType) => state.additons);

  const toastState = (state:any) => {
    if(state === false){
      dispatch(setStateValue({
        type: "notification",
        data: { show: false, type: "", title: "", text: "" },
      }))
    }
  }

  return (
    <CToaster position="top-right">
      <CToast
        show={notification?.show}
        autohide={notification?.autohide??5000}
        onStateChange={toastState}
        fade={true}
        className={`p-2 bg-${notification?.type} text-white`}
      >
        <CToastHeader
          closeButton={true}
          className={`bg-${notification?.type} text-white`}
        >
          {notification?.title}
        </CToastHeader>

        {notification?.text && (
          <CToastBody className={`p-3 bg-${notification?.type} text-white`}>
            {notification.text}
          </CToastBody>
        )}
      </CToast>
    </CToaster>
  );
};

export default Notification;
