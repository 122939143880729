import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import * as reducers from "./reducers";

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ["forecasts/upload-duetto/fulfilled"],
    },
  }),
];

const store = configureStore({
  reducer: combineReducers(reducers),
  middleware,
});

export type AppDispatchType = typeof store.dispatch;
export type StateType = ReturnType<typeof store.getState>;

export default store;
