import { AxiosResponse } from "axios";
import { instance, headers } from "./index";
import { HotelType, HotelInListType } from "../types/hotels";

const hotelsApi = {
  getHotels: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<HotelInListType> }> = await instance.get(
      "/hotels",
      headers()
    );
    return data;
  },

  getHotelById: async (id: number) => {
    const { data }: AxiosResponse<{ data: HotelType }> = await instance.get(
      `/hotels/${id}`,
      headers()
    );
    return data;
  },

  createHotel: async (values: HotelType) => {
    const { data }: AxiosResponse<{ data: HotelType }> = await instance.post(
      "/hotels",
      values,
      headers()
    );
    return data;
  },

  updateHotel: async ({ id, values }: { id: number; values: HotelType }) => {
    const { data }: AxiosResponse<{ data: HotelType }> = await instance.put(
      `/hotels/${id}`,
      values,
      headers()
    );
    return data;
  },

  deleteHotel: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `/hotels/${id}`,
      headers()
    );
    return data;
  },
};

export default hotelsApi;
