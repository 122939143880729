import { createSlice } from "@reduxjs/toolkit";
import {
  getHotels,
  getHotelById,
  createHotel,
  updateHotel,
  deleteHotel,
} from "../actions/hotels";
import { HotelType, HotelInListType } from "../../types/hotels";

const initialState: {
  hotel: HotelType | null;
  hotels: Array<HotelInListType> | [];
  currentHotelId: number;
  isLoading: boolean;
  errors: any;
} = {
  hotels: [],
  hotel: null,
  currentHotelId: 0,
  isLoading: false,
  errors: null,
};

const hotelsSlice = createSlice({
  name: "hotels",
  initialState,
  reducers: {
    setStateHotels(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    resetHotel(state) {
      return {
        ...state,
        hotel: null,
      };
    },
  },
  extraReducers: {
    [getHotels.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getHotels.fulfilled.toString()]: (state, { payload }) => {
      state.hotels = payload;
      state.isLoading = false;
    },
    [getHotels.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getHotelById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getHotelById.fulfilled.toString()]: (state, { payload }) => {
      state.hotel = payload;
      state.isLoading = false;
    },
    [getHotelById.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [createHotel.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [createHotel.fulfilled.toString()]: (state, { payload }) => {
      state.hotel = payload;
      state.isLoading = false;
    },
    [createHotel.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [updateHotel.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [updateHotel.fulfilled.toString()]: (state, { payload }) => {
      state.hotel = payload;
      state.isLoading = false;
    },
    [updateHotel.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [deleteHotel.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [deleteHotel.fulfilled.toString()]: (state) => {
      state.hotels = state.hotels.filter(
        (item) => item.id !== state.currentHotelId
      );
      state.isLoading = false;
    },
    [deleteHotel.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export const { setStateHotels, resetHotel } = hotelsSlice.actions;

export default hotelsSlice.reducer;
