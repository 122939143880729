import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import {
  ConstantType,
  ConstantFullType,
  ParametersConstantType,
} from "../../types/constants";

export const getConstants = createAsyncThunk(
  "constants/get-constants",
  async (params: ParametersConstantType | undefined, thunkAPI) => {
    try {
      const {
        data,
      }: { data: Array<ConstantType> } = await api.constants.getConstants(
        params
      );
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getConstantById = createAsyncThunk(
  "constants/get-constant-by-id",
  async (id: number, thunkAPI) => {
    try {
      const {
        data,
      }: { data: ConstantFullType } = await api.constants.getConstantById(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const createConstant = createAsyncThunk(
  "constants/create-constant",
  async (values: ConstantType, thunkAPI) => {
    try {
      const { data } = await api.constants.createConstant(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateConstant = createAsyncThunk(
  "constants/update-constant",
  async (values: { id: number; values: any }, thunkAPI) => {
    try {
      const { data } = await api.constants.updateConstant(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteConstant = createAsyncThunk(
  "constants/delete-constant",
  async (id: number, thunkAPI) => {
    try {
      const { data }: { data: string } = await api.constants.deleteConstant(id);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
