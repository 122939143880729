import { AxiosResponse } from "axios";
import { instance, headers } from "./index";
import {
  ConstantType,
  ConstantFullType,
  ParametersConstantType,
} from "../types/constants";

const constantApi = {
  getConstants: async (params: ParametersConstantType | undefined) => {
    const {
      data,
    }: AxiosResponse<{ data: Array<ConstantType> }> = await instance.get(
      `/constants${params?.countryID && `?countryID=${params.countryID}`}`,
      headers()
    );
    return data;
  },

  getConstantById: async (id: number) => {
    const {
      data,
    }: AxiosResponse<{ data: ConstantFullType }> = await instance.get(
      `/constants/${id}`,
      headers()
    );
    return data;
  },

  createConstant: async (values: any) => {
    const { data }: AxiosResponse<{ data: ConstantType }> = await instance.post(
      "/constants",
      values,
      headers()
    );
    return data;
  },

  updateConstant: async ({
    id,
    values,
  }: {
    id: number;
    values: { name: number; value: number };
  }) => {
    const { data }: AxiosResponse<{ data: ConstantType }> = await instance.put(
      `/constants/${id}`,
      values,
      headers()
    );
    return data;
  },

  deleteConstant: async (id: number) => {
    const { data }: AxiosResponse<{ data: string }> = await instance.delete(
      `/constants/${id}`,
      headers()
    );
    return data;
  },
};

export default constantApi;
