import { createSlice } from "@reduxjs/toolkit";

import {
  signOut,
  getProfile,
  updateProfile,
  changePassword,
  getProfileByCode,
} from "../actions/user";
import { ProfileType } from "../../types/user";

type InitialStateType = {
  profile: ProfileType | null;
  invitedProfile: ProfileType | null;
  role: string | null;
  role_id: number | null;
  isLoading: boolean;
  errors: any;
};

const initialState: InitialStateType = {
  profile: null,
  invitedProfile: null,
  role: null,
  role_id: null,
  isLoading: false,
  errors: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setStateValue(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
    logout(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("forecastValues"); // TODO
      localStorage.removeItem("hotelSettings");
      return {
        ...initialState,
        invitedProfile: state.invitedProfile,
      };
    },
  },
  extraReducers: {
    [getProfile.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getProfile.fulfilled.toString()]: (state, { payload }) => {
      state.profile = payload;
      state.role = payload?.role?.code;
      state.role_id = payload?.user_role_id;
      state.isLoading = false;
    },
    [getProfile.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getProfileByCode.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getProfileByCode.fulfilled.toString()]: (state, { payload }) => {
      state.invitedProfile = payload;
      state.isLoading = false;
    },
    [getProfileByCode.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [updateProfile.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [updateProfile.fulfilled.toString()]: (state, { payload }) => {
      state.profile = payload.data;
      state.isLoading = false;
      state.errors = null;
    },
    [updateProfile.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [changePassword.fulfilled.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
    },
    [changePassword.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [signOut.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [signOut.fulfilled.toString()]: (state, { payload }) => {
      localStorage.removeItem("token");
      localStorage.removeItem("forecastValues"); // TODO
      return {
        ...initialState,
        invitedProfile: state.invitedProfile,
      };
    },
    [signOut.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export const { setStateValue, logout } = userSlice.actions;

export default userSlice.reducer;
