import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { StateType } from "../../redux/store";

type GuestRouteProps = {
  // exact: boolean;
  path: string;
  component: React.ComponentType<any>;
};

const GuestRoute: React.FC<GuestRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const profile = useSelector((state: StateType) => state.user.profile);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!profile || props.match.params?.action === 'invite') {
          return <Component {...props} />;
        }

        return <Redirect to="/" />;
      }}
    />
  );
};

export default GuestRoute;
