import { createSlice } from "@reduxjs/toolkit";
import {
  getHotelSettings,
  updateHotelSettings,
} from "../actions/hotelSettings";
import { HotelSettingsType } from "../../types/hotelSettings";
import { setInitialValuesForSettings } from "../../helpers/formsFields";

const initialState: {
  settings: Array<HotelSettingsType> | [];
  fb_split: Array<HotelSettingsType> | [];
  ice_split: Array<HotelSettingsType> | [];
  initialValuesForSettings: any;
  updated: boolean;
  isLoading: boolean;
  errors: any;
} = {
  settings: [],
  fb_split: [],
  ice_split: [],
  initialValuesForSettings: {},
  updated: false,
  isLoading: false,
  errors: null,
};

const hotelSettingsSlice = createSlice({
  name: "hotelSettings",
  initialState,
  reducers: {
    resetHotelSettings() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: {
    [getHotelSettings.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getHotelSettings.fulfilled.toString()]: (state, { payload }) => {
      state.settings = payload.common_settings || [];
      state.fb_split = payload["fb-split"] || [];
      state.ice_split = payload["ice-split"] || [];
      state.initialValuesForSettings =
        payload["fb-split"] && payload["ice-split"] && 
        setInitialValuesForSettings([
          ...payload["fb-split"],
          ...payload["ice-split"],
        ]);
      state.isLoading = false;
    },
    [getHotelSettings.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [updateHotelSettings.pending.toString()]: (state) => {
      state.updated = false;
      state.isLoading = true;
      state.errors = null;
    },
    [updateHotelSettings.fulfilled.toString()]: (state) => {
      state.updated = true;
      state.isLoading = false;
    },
    [updateHotelSettings.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.message;
    },
  },
});

export const { resetHotelSettings } = hotelSettingsSlice.actions;

export default hotelSettingsSlice.reducer;
