import { createSlice } from "@reduxjs/toolkit";
import {
  getConstants,
  getConstantById,
  createConstant,
  updateConstant,
  deleteConstant,
} from "../actions/constants";

import { ConstantType } from "../../types/constants";

const initialState: {
  constant: ConstantType | null;
  constants: Array<ConstantType> | [];
  currentConstantId: number;
  isLoading: boolean;
  success: boolean;
  errors: any;
} = {
  constant: null,
  constants: [],
  currentConstantId: 0,
  isLoading: false,
  success: false,
  errors: null,
};

const constantsSlice = createSlice({
  name: "constants",
  initialState,
  reducers: {
    setStateConstants(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
  },
  extraReducers: {
    [getConstants.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getConstants.fulfilled.toString()]: (state, { payload }) => {
      state.constants = payload;
      state.isLoading = false;
    },
    [getConstants.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getConstantById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.success = false;
      state.errors = null;
    },
    [getConstantById.fulfilled.toString()]: (state, { payload }) => {
      state.constant = payload;
      state.isLoading = false;
    },
    [getConstantById.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [createConstant.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [createConstant.fulfilled.toString()]: (state, { payload }) => {
      state.constant = payload;
      state.success = true;
      state.isLoading = false;
    },
    [createConstant.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [updateConstant.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
      state.success = false;
    },
    [updateConstant.fulfilled.toString()]: (state, { payload }) => {
      state.constant = payload;
      state.success = true;
      state.isLoading = false;
    },
    [updateConstant.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.message;
      state.success = false;
    },
    [deleteConstant.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [deleteConstant.fulfilled.toString()]: (state) => {
      state.constants = state.constants.filter(
        (item) => item.id !== state.currentConstantId
      );
      state.isLoading = false;
    },
    [deleteConstant.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export const { setStateConstants } = constantsSlice.actions;

export default constantsSlice.reducer;
