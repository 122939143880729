import { instance, headers } from "./index";
import { UserDataType } from "../redux/reducers/users";

const usersApi = {
  getUsers: async (values: any) => {
    const requestData = headers();
    const { data } = await instance.get("/users", {
      ...requestData,
      ...{ params: values },
    });
    return data;
  },

  getUserById: async (id: number) => {
    const { data } = await instance.get(`/users/${id}`, headers());
    return data;
  },

  createUser: async (values: any) => {
    let userData = filterHotelsIds(JSON.parse(JSON.stringify(values)));
    const { data } = await instance.post("/users", userData, headers());
    return data;
  },

  updateUser: async (values: any) => {
    let userData = filterHotelsIds(JSON.parse(JSON.stringify(values.data)));
    const { data } = await instance.put(
      `/users/${values.id}`,
      userData,
      headers()
    );
    return data;
  },

  deleteUser: async (id: number) => {
    const { data } = await instance.delete(`/users/${id}`, headers());
    return data;
  },

  resendInvite: async (id: number) => {
    const { data } = await instance.put(
      `/users/resend-invite/${id}`,
      headers()
    );
    return data;
  },
};

export const filterHotelsIds = (userData: UserDataType) => {
  let hotelsIds: any = [];
  userData.hotels &&
    userData.hotels.forEach((item: any) => {
      if (item.hasOwnProperty("id")) {
        hotelsIds.push(item.id);
      } else {
        hotelsIds.push(item.value);
      }
    });
  userData.hotels = hotelsIds;

  return userData;
};

export default usersApi;
