import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import { UpdateProfileType } from "../../types/users";

export const getUsers = createAsyncThunk("users/get-users", async (values: any | {} | null, thunkAPI) => {
  try {
    const { data } = await api.users.getUsers(values);
    return data;
  } catch (err) {}
});

export const getUserById = createAsyncThunk(
  "users/get-user-by-id",
  async (id: number) => {
    try {
      const { data } = await api.users.getUserById(id);
      return data;
    } catch (err) {}
  }
);

export const createUser = createAsyncThunk(
  "users/create-user",
  async (values: UpdateProfileType | {}, thunkAPI) => {
    try {
      const { data } = await api.users.createUser(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/update-user",
  async (values: UpdateProfileType | {}, thunkAPI) => {
    try {
      const { data } = await api.users.updateUser(values);
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/delete-user",
  async (id: number) => {
    try {
      const { data } = await api.users.deleteUser(id);
      return data;
    } catch (err) {}
  }
);

export const resendInvite = createAsyncThunk(
  "users/resend-invite",
  async (id: number) => {
    try {
      const { data } = await api.users.resendInvite(id);
      return data;
    } catch (err) {}
  }
);
