import React from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from "@coreui/react";

type PropsType = {
  title?: string;
  modal?: boolean;
  onOpened?: () => void;
  onSubmit?: () => void;
  isSubmit?: boolean;
  toggle: () => void;
  color?: string;
  size?: "" | "sm" | "lg" | "xl" | undefined;
  textSaveButton?: string;
  withCancel?: boolean;
};

const Modal: React.FC<PropsType> = ({
  title,
  modal,
  onSubmit,
  onOpened,
  isSubmit,
  toggle,
  color = "primary",
  size,
  children,
  textSaveButton = "Save",
  withCancel = true,
}) => {
  const onClick = async () => {
    await (onSubmit && onSubmit());
    toggle();
  };

  return (
    <CModal onOpened={onOpened} show={modal} onClose={toggle} color={color} size={size}>
      {title && (
        <CModalHeader closeButton>
          <big className="font-weight-bold">{title}</big>
        </CModalHeader>
      )}

      <CModalBody>{children}</CModalBody>

      {!isSubmit ? (
        <CModalFooter>
          <CButton
            color={color === "success" ? "success" : "primary"}
            onClick={onClick}
          >
            {textSaveButton}
          </CButton>{" "}
          {withCancel ? (
            <CButton color="danger" onClick={toggle}>
              Cancel
            </CButton>
          ) : null}
        </CModalFooter>
      ) : null}
    </CModal>
  );
};

export default Modal;
