import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CRow,
} from "@coreui/react";
import { useFormik } from "formik";
import * as yup from "yup";

import Input from "../../common/Input/Input";
import { signIn } from "../../../redux/actions/auth";
import { SignInType } from "../../../types/auth";
import { StateType } from "../../../redux/store";

import LogoImage from "../../../assets/images/xenia.png";

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const { errors, isLoading } = useSelector((state: StateType) => state.auth);

  const validationSchema = yup.object({
    email: yup.string().email().required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: validationSchema,
    onSubmit: (values: SignInType) => {
      dispatch(signIn(values));
    },
  });

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={formik.handleSubmit}>
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>

                    <CRow>
                      <CCol>
                        <Input
                          type="email"
                          name="email"
                          label="Email"
                          value={formik.values.email}
                          handleChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />

                        <Input
                          type="password"
                          name="password"
                          label="Password"
                          value={formik.values.password}
                          handleChange={formik.handleChange}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                        />

                        {typeof errors === "string" && (
                          <div className="error my-3">
                            {errors === "Unauthorized"
                              ? "Sorry, you entered an incorrect email address or password."
                              : errors}
                          </div>
                        )}
                      </CCol>
                    </CRow>

                    <CRow>
                      <CCol xs="6">
                        <CButton
                          color="primary"
                          className="px-4"
                          onClick={formik.handleSubmit}
                          disabled={isLoading}
                        >
                          Login
                        </CButton>
                      </CCol>

                      <CCol xs="6" className="text-right">
                        <Link
                          to={`/auth/forgot-password`}
                          className="px-0"
                          color="link"
                        >
                          Forgot password?
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>

              <CCard
                className="text-white bg-primary py-5 d-md-down-none"
                style={{ width: "44%" }}
              >
                <CCardBody className="d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={LogoImage}
                    className="rounded mx-auto d-block mh-100px mb-4"
                    alt="xenia"
                  />
                  <h2>Xenia Forecasting</h2>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default LoginPage;
