import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CButton,
  CHeader,
  CToggler,
  CHeaderNav,
  CBreadcrumbRouter,
} from "@coreui/react";

// routes config
import routes from "../../routes/routes";
import { TheHeaderDropdown } from "./index";

import shrinkScreen from "../../assets/images/shrink-screen.png";
import expandScreen from "../../assets/images/expand-screen.png";

import { ROLES } from "../../helpers/constants";
import { setStateValue } from "../../redux/reducers/additions";
import { StateType } from "../../redux/store";

const TheHeader: React.FC = () => {
  const dispatch = useDispatch();
  const { sidebarShow, maximusView } = useSelector(
    (state: StateType) => state.additons
  );
  const { role, profile } = useSelector((state: StateType) => state.user);

  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow as string | boolean)
      ? false
      : "responsive";
    dispatch(setStateValue({ type: "sidebarShow", data: val }));
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow as string | boolean)
      ? true
      : "responsive";
    dispatch(setStateValue({ type: "sidebarShow", data: val }));
  };

  const maxView = () => {
    dispatch(setStateValue({ type: "sidebarShow", data: false }));
    dispatch(setStateValue({ type: "maximusView", data: true }));
  };

  const minView = () => {
    if (window.innerWidth < 992) {
      dispatch(setStateValue({ type: "sidebarShow", data: false }));
    } else {
      dispatch(setStateValue({ type: "sidebarShow", data: true }));
    }
    dispatch(setStateValue({ type: "maximusView", data: false }));
  };

  return (
    <>
      {!maximusView ? (
        <CHeader className="justify-content-between">
          <div className="d-flex">
            <CToggler inHeader className="ml-2 pb-1 pr-0" onClick={maxView}>
              <img src={expandScreen} alt="shrink screen" />
            </CToggler>
            <CToggler
              inHeader
              className="d-lg-none pl-0"
              onClick={toggleSidebarMobile}
            />
          </div>

          <CToggler
            inHeader
            className="d-md-down-none pl-0"
            onClick={toggleSidebar}
          />

          <CBreadcrumbRouter
            className="border-0 c-subheader-nav m-0 px-1 px-md-3"
            routes={routes}
          />

          <CHeaderNav className="d-md-down-none mr-auto" />

          <CHeaderNav className="px-3 d-flex flex-column profile-menu">
            <TheHeaderDropdown />
            {role === ROLES.INPUT_MAKER && (
              <div className="mb-2">{profile?.hotel?.name}</div>
            )}
          </CHeaderNav>
        </CHeader>
      ) : (
        <CButton className="d-flex ml-4" type="button" onClick={minView}>
          <img src={shrinkScreen} alt="shrink screen" />
        </CButton>
      )}
    </>
  );
};

export default TheHeader;
