import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";

import DefaultRoute from "./default";
import GuestRoute from "./hoc/GuestRoute";
import PrivateRoute from "./hoc/PrivateRoute";
import AuthPage from "../views/pages/AuthPages/AuthPage";
import HomePage from "../views/pages/HomePages/HomePages";
import Notification from "../views/components/Notification/Notification";

import { getProfile } from "../redux/actions/user";
import { getForecastStatus } from "../redux/actions/forecasts";
import { setStateValue } from "../redux/reducers/additions";
import { StateType } from "../redux/store";
import { instance } from "../api";
import { logout } from "../redux/reducers/user";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const { profile } = useSelector((state: StateType) => state.user);
  const { user } = useSelector((state: StateType) => state.auth);
  const [loading, setLoading] = useState(true);
  const [isForecastDetails, setLocation] = useState(false);

  const getMe = async () => {
    if (token) {
      dispatch(getForecastStatus());
      await dispatch(getProfile());
    }

    setLoading(false);
  };

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    const path = location.pathname.includes("forecasts/");
    setLocation(path);
  }, [location]);

  useEffect(() => {
    if (token && !profile && user) {
      setLoading(true);
      getMe();
    }
  }, [token, user, dispatch]);

  useEffect(() => {
    const interceptor = instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.request.status === 401) {
          dispatch(
            setStateValue({
              type: "notification",
              data: {
                show: true,
                type: "danger",
                title: error.request.statusText || "Unauthorized",
              },
            })
          );

          if (!window.location.href.includes("/auth")) {
            window.location.href = `${window.location.origin}/auth/login`;
          }
        }

        if (error.request.status === 500 || error.request.status === 429) {
          console.log('error.request',error.request);
          dispatch(
            setStateValue({
              type: "notification",
              data: {
                show: true,
                type: "danger",
                title: error.request.statusText || "Internal Server Error",
              },
            })
          );

          if (!localStorage.getItem("token")) {
            setTimeout(() => {
              window.location.href = `${window.location.origin}`;
            }, 2000);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      instance.interceptors.request.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    const handleInvalidToken = (e: any) => {
      if (e.key === "token" && e.oldValue && !e.newValue) {
        dispatch(logout());
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);

  return (
    <>
      <Notification />

      {!loading && (
        <div className={`main ${isForecastDetails ? "forecast-details" : ""}`}>
          <Switch>
            <Route path="/" exact component={DefaultRoute} />

            <GuestRoute path="/auth/:action" component={AuthPage} />

            <PrivateRoute
              path="/admin/:category?/:id?/:type?"
              exact
              component={HomePage}
            />
          </Switch>
        </div>
      )}
    </>
  );
};

export default App;
