import { createSlice } from "@reduxjs/toolkit";
import {
  getCountries,
  getCountryById,
  createCountry,
  updateCountry,
  deleteCountry,
} from "../actions/countries";

import { CountryType } from "../../types/countries";

const initialState: {
  country: CountryType | null;
  countries: Array<CountryType> | [];
  currentCountrytId: number;
  isLoading: boolean;
  errors: any;
} = {
  country: null,
  countries: [],
  currentCountrytId: 0,
  isLoading: false,
  errors: null,
};

const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    setStateCountries(state, { payload }) {
      return {
        ...state,
        [payload.type]: payload.data,
      };
    },
  },
  extraReducers: {
    [getCountries.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getCountries.fulfilled.toString()]: (state, { payload }) => {
      state.countries = payload;
      state.isLoading = false;
    },
    [getCountries.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [getCountryById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [getCountryById.fulfilled.toString()]: (state, { payload }) => {
      state.country = payload;
      state.isLoading = false;
    },
    [getCountryById.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [createCountry.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [createCountry.fulfilled.toString()]: (state, { payload }) => {
      state.country = payload;
      state.isLoading = false;
    },
    [createCountry.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [updateCountry.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [updateCountry.fulfilled.toString()]: (state, { payload }) => {
      state.country = payload;
      state.isLoading = false;
    },
    [updateCountry.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
    [deleteCountry.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = null;
    },
    [deleteCountry.fulfilled.toString()]: (state) => {
      state.countries = state.countries.filter(
        (item) => item.id !== state.currentCountrytId
      );
      state.isLoading = false;
    },
    [deleteCountry.rejected.toString()]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload.errors;
    },
  },
});

export const { setStateCountries } = countriesSlice.actions;

export default countriesSlice.reducer;
