import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";
import {NotificationType} from "../../types/notificationsCenter";

export const getNotifications = createAsyncThunk("notificationsCenter/index", async (values: any | {} | null) => {
  try {
    const {
      data,
    }: { data: Array<NotificationType> } = await api.notificationsCenter.getNotifications(values);
    return data;
  } catch (err) {}
});
