import { AxiosResponse } from "axios";
import { instance, headers } from "./index";
import {
  HotelSettingsType,
  HotelSettingsGetParamsType,
} from "../types/hotelSettings";

const hotelSettingsApi = {
  getHotelSettings: async (params: HotelSettingsGetParamsType) => {
    const {
      data,
    }: AxiosResponse<{ data: Array<HotelSettingsType> }> = await instance.get(
      params.type
        ? `/hotelSettings/${params.id}/${params.type}`
        : `/hotelSettings/${params.id}`,
      headers()
    );
    return data;
  },

  updateHotelSettings: async (values: any) => {
    const {
      data,
    }: AxiosResponse<{ data: Array<HotelSettingsType> }> = await instance.put(
      `/hotelSettings/${values.id}`,
      {data: values.data},
      headers()
    );
    return data;
  },
};

export default hotelSettingsApi;
