import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarMinimizer,
  CSidebarNavItem,
  CSidebarNavDropdown,
} from "@coreui/react";

import { setStateValue } from "../../redux/reducers/additions";
import { StateType } from "../../redux/store";
import { getNavbarElements } from "../../helpers/roles";

const TheSidebar: React.FC = () => {
  const dispatch = useDispatch();
  const { sidebarShow } = useSelector((state: StateType) => state.additons);
  const { role } = useSelector((state: StateType) => state.user);

  const itemsNavbar = role ? getNavbarElements(role) : [];

  return (
    <CSidebar
      show={sidebarShow}
      onShowChange={(val: any) => {
        dispatch(setStateValue({ type: "sidebarShow", data: val }));
      }}
    >
      <CSidebarBrand className="d-md-down-none h3" to="/">
        <div className="c-sidebar-brand-full">Xenia Forecasting</div>
        <div className="c-sidebar-brand-minimized">XF</div>
      </CSidebarBrand>

      <CSidebarNav>
        <CCreateElement
          items={itemsNavbar}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
          }}
        />
      </CSidebarNav>

      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
