import React from "react";
import UserCreate from "../views/pages/HomePages/UsersPage/UserCreate";
import UserEdit from "../views/pages/HomePages/UsersPage/UserEdit";

const HomePage = React.lazy(() => import("../views/pages/HomePages/HomePage"));
const Profile = React.lazy(
  () => import("../views/pages/HomePages/ProfilePage/Profile")
);
const CountriesPage = React.lazy(
  () => import("../views/pages/HomePages/CountriesPage/CountriesPage")
);
const CountryPage = React.lazy(
  () => import("../views/pages/HomePages/CountryPage/CountryPage")
);
const HotelsPage = React.lazy(
  () => import("../views/pages/HomePages/HotelsPage/HotelsPage")
);
const HotelPage = React.lazy(
  () => import("../views/pages/HomePages/HotelPage/HotelPage")
);
const HotelSettingsPage = React.lazy(
  () => import("../views/pages/HomePages/HotelSettingsPage/HotelSettingsPage")
);
const UsersPage = React.lazy(
  () => import("../views/pages/HomePages/UsersPage/Users")
);
const PrognosisPage = React.lazy(
  () => import("../views/pages/HomePages/PrognosisPage/PrognosisPage")
);
const PrognosisDetailsPage = React.lazy(
  () =>
    import("../views/pages/HomePages/PrognosisDetailsPage/PrognosisDetailsPage")
);
const ConstantsPage = React.lazy(
  () => import("../views/pages/HomePages/ConstantsPage/ConstantsPage")
);
const ConstantPage = React.lazy(
  () => import("../views/pages/HomePages/ConstantPage/ConstantPage")
);
const NotificationsCenter = React.lazy(
  () => import("../views/pages/HomePages/NotificationsCenterPage/NotificationsPage")
);

const routes = [
  {
    path: "/admin",
    exact: true,
    name: "Home",
    component: HomePage,
  },
  {
    path: "/admin/profile",
    exact: true,
    name: "Profile",
    component: Profile,
  },
  {
    path: "/admin/countries",
    name: "Countries",
    component: CountriesPage,
    exact: true,
  },
  {
    path: "/admin/countries/:id",
    name: "Country Details",
    component: CountryPage,
    exact: true,
  },
  { path: "/admin/users", exact: true, name: "Users", component: UsersPage },
  {
    path: "/admin/users/create",
    exact: true,
    name: "Users",
    component: UserCreate,
  },
  {
    path: "/admin/users/:id/edit",
    exact: false,
    name: "Edit User",
    component: UserEdit,
  },
  // {
  //   path: "/admin/users/:id",
  //   name: "User Details",
  //   component: UserPage,
  //   exact: true,
  // },
  { path: "/admin/hotels", exact: true, name: "Hotels", component: HotelsPage },
  {
    path: "/admin/hotels/:id",
    name: "Hotel Details",
    component: HotelPage,
    exact: true,
  },
  {
    path: "/admin/forecasts",
    name: "Forecasts",
    component: PrognosisPage,
    exact: true,
  },
  {
    path: "/admin/hotel-settings/:id",
    name: "Forecast ",
    component: HotelSettingsPage,
    exact: true,
  },
  {
    path: "/admin/hotels/:id",
    name: "Hotel Details",
    component: HotelPage,
    exact: true,
  },

  {
    path: "/admin/forecasts/:id/:action?",
    name: "Forecast Details",
    component: PrognosisDetailsPage,
    exact: true,
  },
  {
    path: "/admin/constants",
    name: "Constants",
    component: ConstantsPage,
    exact: true,
  },
  {
    path: "/admin/constants/:id/:action?",
    name: "Constant Details",
    component: ConstantPage,
    exact: true,
  },
  {
    path: "/admin/notifications",
    name: "Notifications",
    component: NotificationsCenter,
    exact: true,
  },
];

export default routes;
