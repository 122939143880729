import { AxiosResponse } from "axios";
import { instance, headers } from "./index";
import { ParameterType } from "../types/parameters";

const parametersApi = {
  getParameters: async () => {
    const {
      data,
    }: AxiosResponse<{ data: Array<ParameterType> }> = await instance.get(
      "/parameters",
      headers()
    );
    return data;
  },
};

export default parametersApi;
